import { UserDevice } from '../../models/_core/user-device';
import { UserDeviceService } from './../_core/user-device/user-device.service';
import { Platform, NavController } from '@ionic/angular';
import { AuthService } from './../_core/auth/auth.service';
import { NavPage } from './../../models/_core/nav-page';
import { Injectable } from '@angular/core';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { User } from 'src/app/models/user';
import { AppDataService } from '../app-data/app-data.service';
import { AppData } from 'src/app/models/app-data';
import { NotificationsService } from '../_core/notifications/notifications.service';

/**
 * ID: bh-navigation
 * Name: BH Navigation Service
 * Description: Service used for managing navigation, main menus, and account options
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2022-15-25 - MW - v1: Initial development
 */
@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  // Define your pages for this app here
  navPages: NavPage[] = [
    {
      name: 'Home',
      navPath: '/tabs/home',
      tabId: 'home',
      ionIcon: 'home',
      roles: ['USER', 'ADMIN', 'SYS_ADMIN']
    },
    // {
    //   name: 'UX Guide',
    //   navPath: '/tabs/ux-guide',
    //   tabId: 'ux-guide',
    //   ionIcon: 'color-palette',
    //   roles: ['USER', 'ADMIN', 'SYS_ADMIN']
    // },
    // {
    //   name: 'Manage',
    //   navPath: '/tabs/manage',
    //   tabId: 'manage',
    //   ionIcon: 'build',
    //   roles: ['ADMIN', 'SYS_ADMIN']
    // }
  ];
  showNotifications = false;
  categories = [ {name: 'featured', ionIcon: 'apps'},{name: 'patient', ionIcon: 'person-circle'},{name: 'employee', ionIcon: 'id-card'}, {name: 'other', ionIcon: 'filter-circle'}];
  subscriptions: Subscription[] = [];
  appData: AppData[] = [];
  accessiblePages = 0;
  navigationLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private deviceService: UserDeviceService,
    private appDataService: AppDataService,
    private notifications: NotificationsService
  ) { }

  checkPrivileges(authUser: User) {
    this.navPages.forEach(page => {
      if (page.roles && page.roles.length > 0) {
        if (authUser) {
          // User logged in, check roles
          const matches = page.roles.filter(r => (r && authUser && authUser.role && r.toLowerCase() === authUser.role.toLowerCase()));
          page.isAccessible = (matches.length > 0);
          //console.log('matches', matches);

        } else {
          // User not logged in
          page.isAccessible = false;
        }
      } else {
        // Page unprotected
        page.isAccessible = true;
      }
    });
    this.accessiblePages = this.navPages.filter(p => p.isAccessible).length;
  }

  async loadNavigation(): Promise<boolean> {
    try {
          for (const a of this.categories) {
            const navPage: NavPage = {
              name: this.convertTitleCase(a.name),
              navPath: '/tabs/explore/' + a.name,
              ionIcon: a.ionIcon,
              //tabPath: 'explore/' + c.id,
              tabId: a.name,
              isAccessible: true,
              roles: ['USER', 'ADMIN', 'SYS_ADMIN']
            }
            const found = this.navPages.filter(n => n.tabId === a.name).length > 0;
            console.log('found', found);

            if(!found) {
              this.navPages.push(navPage);
            }
          }
          //this.navPages.push(this.eventsNavPage);
          this.navigationLoaded.next(true);
          console.log('navigation', this.navPages);
        }
       catch (err) {
      this.notifications.handleError(err, 'loadNavigation');
    }
    return Promise.resolve(true);
  }

  convertTitleCase(phrase: string): string {
    return phrase.split(' ') .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(' ');
  }

  navigateHome(): Promise<boolean> {
    return this.navigateBack('/tabs/home');
  }

  navigateForward(pathUrl, navOptions: NavigationOptions = undefined): Promise<boolean> {
    const userDevice = this.deviceService.getUserDevice();
    if(userDevice.isNarrowViewport) {
      return this.navCtrl.navigateForward(pathUrl, navOptions);
    } else {
      return this.navCtrl.navigateRoot(pathUrl, navOptions);
    }
  }

  navigateBack(pathUrl, navOptions: NavigationOptions = undefined): Promise<boolean> {
    const userDevice = this.deviceService.getUserDevice();
    if(userDevice.isNarrowViewport) {
      return this.navCtrl.navigateBack(pathUrl, navOptions);
    } else {
      return this.navCtrl.navigateRoot(pathUrl, navOptions);
    }
  }

  navigateRoot(pathUrl, navOptions: NavigationOptions = undefined): Promise<boolean> {
    return this.navCtrl.navigateRoot(pathUrl, navOptions);
  }

}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BhAnalyticsRouteGuardService } from './services/_core/bh-analytics-route-guard/bh-analytics-route-guard.service';
import { LoginRouteGuardService } from './services/_core/login-route-guard/login-route-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    pathMatch: 'full',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'help-forgot-pwd',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () =>
      import('./pages/_core/help-forgot-pwd/help-forgot-pwd.module').then(m => m.HelpForgotPwdPageModule)
  },
  {
    path: 'share',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/share/share.module').then(m => m.SharePageModule)
  },
  {
    path: 'help',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'help-no-pin',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/help-no-pin/help-no-pin.module').then(m => m.HelpNoPinPageModule)
  },
  {
    path: 'errors',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/errors/errors.module').then(m => m.ErrorsPageModule)
  },
  {
    path: 'errors-detail',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/errors-detail/errors-detail.module').then(m => m.ErrorsDetailPageModule)
  },
  {
    path: 'check-for-updates',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'Check for Updates', roles: [] },
    loadChildren: () => import('./pages/_core/check-for-updates/check-for-updates.module').then(m => m.CheckForUpdatesPageModule)
  },
  {
    path: 'about',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: 'my-account',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'My Account', roles: ['USER', 'ADMIN', 'SYS_ADMIN'] },
    loadChildren: () => import('./pages/_core/my-account/my-account.module').then(m => m.MyAccountPageModule)
  },
  {
    path: 'bh-input-text-editor',
    loadChildren: () => import('./components/_core/bh-input-text-editor/bh-input-text-editor.module')
      .then(m => m.BhInputTextEditorPageModule)
  },
  {
    path: 'feedback',
    data: { pageName: 'Feedback', roles: [] },
    loadChildren: () => import('./pages/_core/feedback/feedback.module').then(m => m.FeedbackPageModule)
  },
  {
    path: 'notifications',
    data: { pageName: 'Notifications', roles: ['USER', 'ADMIN', 'SYS_ADMIN'] },
    loadChildren: () => import('./pages/_core/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'category',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'details',
    loadChildren: () => import('./pages/details/details.module').then( m => m.DetailsPageModule)
  },
  {
    path: 'reviews',
    loadChildren: () => import('./pages/reviews/reviews.module').then( m => m.ReviewsPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'manage-app-editor',
    loadChildren: () => import('./pages/manage-app-editor/manage-app-editor.module').then( m => m.ManageAppEditorPageModule)
  },
  {
    path: 'installation',
    loadChildren: () => import('./pages/installation/installation.module').then( m => m.InstallationPageModule)
  },
  {
    path: 'manage-apps',
    loadChildren: () => import('./pages/manage-apps/manage-apps.module').then( m => m.ManageAppsPageModule)
  },
  {
    path: 'manage-users',
    loadChildren: () => import('./pages/manage-users/manage-users.module').then( m => m.ManageUsersPageModule)
  },
  {
    path: 'manage-user-editor',
    loadChildren: () => import('./pages/manage-user-editor/manage-user-editor.module').then( m => m.ManageUserEditorPageModule)
  },
  {
    path: 'manage-verlocker',
    loadChildren: () => import('./pages/manage-verlocker/manage-verlocker.module').then( m => m.ManageVerlockerPageModule)
  },
  {
    path: 'manage-verlocker-editor',
    loadChildren: () => import('./pages/manage-verlocker-editor/manage-verlocker-editor.module').then( m => m.ManageVerlockerEditorPageModule)
  },
  {
    path: 'manage-app-access',
    loadChildren: () => import('./pages/manage-app-access/manage-app-access.module').then( m => m.ManageAppAccessPageModule)
  },
  {
    path: 'add-faqs',
    loadChildren: () => import('./pages/add-faqs/add-faqs.module').then( m => m.AddFaqsPageModule)
  },
  {
    path: 'image-viewer',
    loadChildren: () => import('./pages/image-viewer/image-viewer.module').then( m => m.ImageViewerPageModule)
  },
  {
    path: 'manage-app-access-editor',
    loadChildren: () => import('./pages/manage-app-access-editor/manage-app-access-editor.module').then( m => m.ManageAppAccessEditorPageModule)
  },
  {
    path: 'manage-app-feature-editor',
    loadChildren: () => import('./pages/manage-app-feature-editor/manage-app-feature-editor.module').then( m => m.ManageAppFeatureEditorPageModule)
  },
  {
    path: 'manage-app-sub-feature-editor',
    loadChildren: () => import('./pages/manage-app-sub-feature-editor/manage-app-sub-feature-editor.module').then( m => m.ManageAppSubFeatureEditorPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'add-image-popover',
    loadChildren: () => import('./pages/add-image-popover/add-image-popover.module').then( m => m.AddImagePopoverPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
